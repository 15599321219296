<template>
  <main>
    <MainSlider class="mt-8"/>
    <section class="therapy-areas main-page__section">
      <div class="content">
        <div class="main-page__row">
          <div class="main-page__categories" data-id="areaItems">
            <div class="main-page__title">Терапевтические области</div>
            <div>
              <div
                class="main-page__categories-row"
                v-if="Nosologys && Nosologys.length"
              >
                <AreaItem
                  v-for="(area, ind) in filteredNos"
                  :key="ind"
                  :item="formattedImageList(area, 'main')"
                  :fastNav="true"
                />
              </div>

              <div v-else class="preloader detail-page__preloader">
                <Preloader class="" />
              </div>
            </div>
          </div>
          <div class="main-page__calendar" data-id="eventsCalendar">
            <div class="main-page__title">Календарь мероприятий</div>
            <Events />
          </div>
        </div>
      </div>
    </section>
    <section class="materials main-page__section">
      <div class="content">
        <div class="main-page__title">Информационные материалы</div>
        <div class="main-page__materials-row">
          <div
            @click="toInfo({ name: 'Library' }, 'Библиотека')"
            class="main-page__material material main-page__library"
          >
            <picture>
              <source
                media="(max-width: 767px)"
                :srcset="require('@/assets/img/library-mobile.jpg')"
              />
              <source
                media="(max-width: 1220px)"
                :srcset="require('@/assets/img/library-tablet.jpg')"
              />
              <source
                media="(max-width: 99999999px)"
                :srcset="require('@/assets/img/library.jpg')"
              />
              <img
                class="material__image"
                :src="require('@/assets/img/library.jpg')"
              />
            </picture>

            <div class="material__title">Библиотека</div>
            <div class="material__link">
              Подробнее
              <span class="ml-2">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16 8L9 16L6.5 16L12.5 9L-3.0598e-07 9L-3.93402e-07 7L12.5 7L6.5 -2.84124e-07L9 -3.93402e-07L16 8Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div
            @click="toInfo({ name: 'Podcasts' }, 'Подкасты')"
            class="main-page__material material main-page__podcasts"
          >
            <img
              class="material__image"
              :src="require('@/assets/img/podcasts.jpg')"
            />
            <div class="material__title">Подкасты</div>
            <div class="material__podcast-title">
              300+ <span>аудио и видеоподкастов <br />для врачей</span>
            </div>
            <div class="material__link">
              Подробнее
              <span class="ml-2">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16 8L9 16L6.5 16L12.5 9L-3.0598e-07 9L-3.93402e-07 7L12.5 7L6.5 -2.84124e-07L9 -3.93402e-07L16 8Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div
            @click="toInfo({ name: 'VideoMaterials' }, 'Видеоматериалы')"
            class="
              main-page__material
              material
              main-page__video
              material_horizontal
            "
          >
            <div class="material__title">Видеоматериалы</div>
            <div class="material__text-wrap">
              <div class="material__description">
                Коллекция вебинаров, конференций, интервью и семинаров. Ведущие
                специалисты со всего мира.
              </div>
              <div class="material__link">
                Подробнее
                <span class="ml-2">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16 8L9 16L6.5 16L12.5 9L-3.0598e-07 9L-3.93402e-07 7L12.5 7L6.5 -2.84124e-07L9 -3.93402e-07L16 8Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Helper
      v-if="showHelper && helperTargets[currentTarget]"
      @setPosition="setPosition"
      @nextStep="nextStep"
      :target="helperTargets[currentTarget]"
      :position="helperPosition"
      :index="currentTarget"
      :length="helperTargets.length"
      :class="{ topPosition: helperInTop }"
      @close="closeHelp"
    />
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AreaItem from "../components/main/AreaItem.vue";
import Events from "../components/main/events/Events.vue";
import MainSlider from "../components/main/MainSliderNew.vue";
import { bus } from "@/main";
import Helper from "@/components/main/Helper.vue";
import Preloader from "@/components/Preloader.vue";

export default {
  metaInfo: {
    title: "Информационный портал для работников здравоохранения ",
    description:
      "Цель портала — повысить осведомленность врачей о заболеваниях и современных подходах к терапии",
  },
  components: { MainSlider, Events, AreaItem, Helper, Preloader },
  name: "MainPage",
  data: () => ({
    helperInTop: false,
    showHelper: false,
    currentTarget: 0,
    helperPosition: {},
    helperTargets: [
      {
        description:
          "Актуальная информация по проблематике и терапии основных заболеваний",
        icon: require("@/assets/img/onboarding/icon1.svg"),
        key: "headerNos",
        position: "bottom",
      },
      {
        icon: require("@/assets/img/onboarding/icon2.svg"),
        description:
          "Инструкции по применению и подробные сведения о препаратах «АстраЗенека»",
        key: "headerMed",
        position: "bottom",
      },
      {
        icon: require("@/assets/img/onboarding/icon3.svg"),
        description:
          "Научные материалы о заболеваниях и современных подходах к терапии: статьи, видеоролики и подкасты",
        key: "headerLib",
        position: "bottom",
      },
      {
        icon: require("@/assets/img/onboarding/icon4.svg"),
        description:
          "Коллекция записей прошедших симпозиумов и предстоящие события «АстраЗенека»",
        key: "headerEvents",
        position: "bottom",
      },
      {
        icon: require("@/assets/img/onboarding/icon5.svg"),
        description:
          "Интерактивные материалы по нозологиям, цифровые клиники, тренинги для врачей и многое другое",
        key: "headerProjects",
        position: "bottom",
      },
      {
        icon: require("@/assets/img/onboarding/icon7.svg"),
        description:
          "Здесь Вы можете сохранить полезные материалы для быстрого доступа, а также получить уникальные подборки, соответствующие вашим интересам",
        key: "headerCabinet",
        position: "bottom-right",
      },
      {
        icon: require("@/assets/img/onboarding/icon6.svg"),
        description: "Быстрый поиск материалов на портале AZ-MOST",
        key: "headerSearch",
        position: "bottom",
      },
    ],
  }),
  computed: {
    ...mapGetters(["Nosologys", "user"]),
    filteredNos() {
      return this.Nosologys.filter((el) =>
        el.images.find((img) => img.tag === "main")
      );
    },
  },
  methods: {
    ...mapActions(["checkTutorial"]),
    formattedImageList(item, tag) {
      if (item.images && item.images.length) {
        let imageList = [];
        imageList.push({
          src:
            this.$root.host + item.images?.find((el) => el.tag === tag)?.image,
          media: "(max-width: 99999999px)",
        });
        item.image = imageList;
      }

      return item;
    },
    toInfo(to, text) {
      const vm = this;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "main info materials click", {
          "main info materials click": {
            "material name": text,
            ...this.$root.ymFields,
          },
        });
      }
      this.$router.push(to).catch(() => {});
    },
    startHelp() {
      this.showHelper = true;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "onboarding start", {
          "onboarding start": {
            "banner interaction": "start",
            ...this.$root.ymFields,
          },
        });
        ym(91468266, "reachGoal", "onboarding", {
          onboarding: {
            "/": 1,
            ...this.$root.ymFields,
          },
        });
      }
    },
    closeHelp() {
      this.showHelper = false;
      document.querySelector("body").style.pointerEvents = "";
      document.querySelectorAll(".highlightedElement").forEach((item) => {
        item.classList.remove("highlightedElement");
      });
      window.scrollTo({ top: 0, behavior: "smooth" });
      bus.$emit("scrollLock", false);
      this.checkTutorial({ id: this.user.id, flags: ["tutorial"] });
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "onboarding start", {
          "onboarding start": {
            "banner interaction": "skip",
            ...this.$root.ymFields,
          },
        });
      }
    },
    setPosition(target) {
      if (this.$root.isMobile || this.$root.isTablet) {
        if (this.currentTarget === 0) {
          bus.$emit("openMenu");
        }
        if (this.currentTarget === 6) {
          this.helperInTop = false;
          bus.$emit("closeMenu");
        }
        if (this.currentTarget === 5) {
          this.helperInTop = true;
        }
      }
      document.querySelector("body").style.pointerEvents = "none";
      bus.$emit("scrollLock", true);
      let relativePos = {};
      if (!target.key && document.querySelector(`[data-id="${target.key}"]`))
        return;
      let child = document.querySelector(`[data-id="${target.key}"]`);
      let parentPos = document.body.getBoundingClientRect();
      let childPos = child.getBoundingClientRect();
      const yOffset = -100;
      const y =
        child.getBoundingClientRect().top + window.pageYOffset + yOffset;
      if (!target.key.includes("header")) {
        window.scrollTo({ top: y, behavior: "smooth" });
      }
      if (child.closest(".header-menu_mob") && target.key === "headerCabinet") {
        child
          .closest(".header-menu_mob")
          .scrollTo({ top: 300, behavior: "smooth" });
      }

      document.querySelectorAll(".highlightedElement").forEach((item) => {
        item.classList.remove("highlightedElement");
      });
      child.classList.add("highlightedElement");
      relativePos.top = childPos.top + window.scrollY;
      relativePos.right = childPos.right - parentPos.right;
      relativePos.bottom = childPos.bottom - parentPos.bottom;
      relativePos.left = childPos.left;
      relativePos.childWidth = childPos.width;
      relativePos.childHeight = childPos.height;
      this.helperPosition = relativePos;
    },
    nextStep() {
      let routeArray = this.$route.path.split("/");
      if (this.currentTarget < this.helperTargets.length - 1) {
        this.currentTarget++;
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "onboarding", {
            onboarding: {
              [routeArray[routeArray.length - 1] || "/"]:
                this.currentTarget + 1,
              ...this.$root.ymFields,
            },
          });
        }

        this.setPosition(this.helperTargets[this.currentTarget]);
      } else {
        this.showHelper = false;
        document.querySelector("body").style.pointerEvents = "";
        document.querySelectorAll(".highlightedElement").forEach((item) => {
          item.classList.remove("highlightedElement");
        });
        window.scrollTo({ top: 0, behavior: "smooth" });
        bus.$emit("scrollLock", false);
        this.checkTutorial({ id: this.user.id, flags: ["tutorial"] });
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "onboarding finish - header", {
            onboarding: {
              finish: "header",
              ...this.$root.ymFields,
            },
          });
        }
      }
    },
  },
  mounted() {
    bus.$on("startHelp", this.startHelp);
    if (this.$route && this.$route.params && this.$route.params.tg) {
      bus.$emit("tgPopup", true);
    }
  },
  beforeDestroy() {
    bus.$off("startHelp", this.startHelp);
  },
};
</script>

<style lang="scss" scoped>
.main-page {
  &__row {
    display: grid;
    column-gap: 32px;
    grid-template-columns: 2fr 384px;

    @media screen and (max-width: 1220px) {
      display: block;
    }
  }

  &__section {
    padding-top: 80px;

    @media screen and (max-width: 1220px) {
      padding-top: 40px;
    }
  }

  &__title {
    margin-bottom: 40px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #1f1f1f;

    @media screen and (max-width: 1220px) {
      margin-bottom: 32px;
    }
  }

  &__categories-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 32px;
    row-gap: 24px;

    @media screen and (max-width: 767px) {
      column-gap: 16px;
    }
  }

  &__materials-row {
    display: grid;
    grid-template-columns: 492px 692px;
    grid-template-rows: 1fr;
    column-gap: 32px;
    row-gap: 24px;

    @media screen and (max-width: 1220px) {
      display: block;
    }
  }

  &__categories {
    @media screen and (max-width: 1220px) {
      margin-bottom: 40px;
    }
  }
}

.material {
  position: relative;
  height: 625px;
  padding: 64px;
  display: flex;
  flex-direction: column;
  color: #1f1f1f;
  cursor: pointer;

  &:hover .material__link {
    color: #da338c;
  }

  @media screen and (max-width: 1220px) {
    height: 320px;
    padding: 24px;
    margin-bottom: 16px;
  }

  @media screen and (max-width: 767px) {
    height: 205px;
  }

  &_horizontal {
    flex-direction: row;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__text-wrap {
    max-width: 45%;
    margin-left: auto;
    margin-right: auto;
    border-left: 1px solid #b3bb61;
    padding-left: 62px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1220px) {
      margin: auto 0;
      max-width: none;
      border: 0;
      padding: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  &__description {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #1f1f1f;

    @media screen and (max-width: 1220px) {
      margin: auto 0 0;
    }

    @media screen and (max-width: 767px) {
      margin-top: 16px;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &.main-page__video {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    background-color: #c4d600;
    padding-right: 0;
    height: 288px;
    @media screen and (max-width: 1220px) {
      height: 320px;
      padding-right: 24px;
    }

    @media screen and (max-width: 767px) {
      height: 205px;
    }
  }

  &__podcast-title {
    margin: auto 0;
    position: relative;
    z-index: 2;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 211px;
    line-height: 228px;
    color: #830051;

    @media screen and (max-width: 1220px) {
      font-size: 131px;
      line-height: 142px;
      width: fit-content;
    }

    @media screen and (max-width: 767px) {
      font-size: 64px;
      line-height: 78px;
    }

    & span {
      position: absolute;
      bottom: 0;
      right: 0;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #3c4242;

      @media screen and (max-width: 1220px) {
        bottom: auto;
        top: 90%;
      }

      @media screen and (max-width: 1220px) {
        left: 95%;
        right: auto;
        top: 60%;
      }
    }
  }

  &__title {
    position: relative;
    z-index: 2;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 35px;
    color: inherit;

    @media screen and (max-width: 767px) {
      font-size: 21px;
      line-height: 27px;
    }
  }

  &__link {
    margin-top: auto;
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    transition: 0.3s;
  }
}
</style>