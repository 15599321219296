<template>
  <section class="first-slider" v-if="Slides && Slides.length">
    <div class="content">
      <VueSlickCarousel
        ref="firstSlider"
        @init="onInit"
        @beforeChange="beforeChange"
        @afterChange="afterChange"
        v-bind="settings"
      >
        <template #default>
          <div
            class="first-slider__slide"
            v-for="slide in Slides"
            :key="slide.id"
          >
            <picture v-if="formattedImageList && formattedImageList.length">
              <source
                v-for="(img, ind) in formattedImageList(slide)"
                :key="ind"
                :srcset="img.src"
                :media="`(max-width: ${img.media}px)`"
              />
              <img
                class="first-slider__slide-image"
                v-if="slide && slide.banner_desktop"
                :src="$root.host + slide.banner_desktop"
              />
            </picture>
            <div class="first-slider__slide-text">
              <div
                class="first-slider__slide-caption"
                v-html="slide.title"
              ></div>
              <div
                v-if="slide.description"
                class="first-slider__slide-description"
                v-html="slide.description"
              ></div>
            </div>
          </div>
        </template>
      </VueSlickCarousel>
    </div>
    <div class="first-slider__nav" v-if="slideCount > 1">
      <div class="content">
        <div class="first-slider__nav-row">
          <div class="first-slider__counter">
            {{ currentSlide }}/{{ slideCount }}
          </div>
          <div class="first-slider__arrows">
            <div
              class="first-slider__arrow first-slider__arrow_prev"
              @click="$refs.firstSlider.prev()"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.49691e-07 8L7 16L9.5 16L3.5 9L16 9L16 7L3.5 7L9.5 -2.84124e-07L7 -3.93402e-07L3.49691e-07 8Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <div
              class="first-slider__arrow first-slider__arrow_next"
              @click="$refs.firstSlider.next()"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16 8L9 16L6.5 16L12.5 9L-3.0598e-07 9L-3.93402e-07 7L12.5 7L6.5 -2.84124e-07L9 -3.93402e-07L16 8Z"
                  fill="currentColor"
                />
              </svg>
              <div
                class="first-slider__arrow-progress"
                :class="{ 'first-slider__arrow-progress_active': loadAnim }"
              >
                <svg
                  viewPort="0 0 100 100"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle stroke="#830051" fill="transparent"></circle>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
export default {
  name: "MainSlider",
  components: { VueSlickCarousel },
  data: () => ({
    currentSlide: 1,
    slideCount: 0,
    loadAnim: false,
    autoplayInterval: null,
    settings: {
      arrows: false,
      dots: false,
    },
    media: {
      mobile: 767,
      tablet: 1220,
      desktop: 9999999,
    },
  }),
  computed: {
    ...mapGetters(["Slides"]),
  },
  methods: {
    ...mapActions(["fetchSlides"]),
    autoplay(time) {
      this.autoplayInterval = setInterval(() => {
        if (
          this.$refs &&
          this.$refs.firstSlider &&
          typeof this.$refs.firstSlider.next === "function"
        ) {
          this.$refs.firstSlider.next();
        }
      }, time);
    },
    beforeChange(oldSlideIndex, newSlideIndex) {
      this.loadAnim = false;
      this.currentSlide = newSlideIndex + 1;
    },
    afterChange() {
      clearInterval(this.autoplayInterval);
      this.autoplay(5000);
      setTimeout(() => {
        this.loadAnim = true;
      }, 10);
    },
    onInit() {
      if (this.Slides.length > 1) {
        this.autoplay(5000);
        this.loadAnim = true;
      }
    },
    formattedImageList(slide) {
      let imageList = [];
      let usedImages = Object.keys(slide)
        .filter((el) => el.includes("banner_"))
        .map((el) => el.replace("banner_", ""));
      for (let imgFormat of usedImages) {
        if (slide["banner_" + imgFormat] && this.media[imgFormat]) {
          imageList.push({
            src:
              this.$root.host +
              slide["banner_" + imgFormat].replace("http:", "https:"),
            media: this.media[imgFormat],
          });
        }
      }
      imageList.sort((a, b) => (a.media > b.media ? 1 : -1));
      return imageList;
    },
  },
  async mounted() {
    await this.fetchSlides();
    this.slideCount = this.Slides.length;
  },
};
</script>

<style lang="scss" scoped>
.first-slider {
  // height: 610px;

  @media screen and (max-width: 1220px) {
    // height: 595px;
  }
  @media screen and (max-width: 767px) {
    // height: 540px;
  }
  &__slide {
    position: relative;
    height: 300px;
    padding: 40px 32px;
    // padding-top: 62px;
    // padding-bottom: 132px;
    // height: 610px;

    @media screen and (max-width: 1220px) {
      padding: 24px 32px;
      // padding-top: 40px;
      // height: 595px;
    }

    @media screen and (max-width: 767px) {
      // height: 540px;
      // padding-top: 24px;
      padding: 24px;
    }

    & > .content {
      height: 100%;

      // position: absolute;
      // top: 0;
      // left: 50%;
      // transform: translateX(-50%);
      // padding-top: 62px;
      // padding-bottom: 132px;

      @media screen and (max-width: 1220px) {
        // padding-top: 40px;
      }

      @media screen and (max-width: 767px) {
        // padding-top: 24px;
      }
    }

    &-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      display: block;

      @media screen and (max-width: 1220px) {
        object-position: bottom;
      }
    }

    &-text {
      position: relative;
      // position: absolute;
      // top: 40px;
      // left: 32px;
      z-index: 2;
      width: clamp(300px, 40%, 350px);
      display: flex;
      flex-direction: column;
      height: 100%;

      @media screen and (max-width: 767px) {
        width: auto;
      }
    }

    &-caption {
      font-family: "Roboto Slab", sans-serif;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      color: #830051;
      @media screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 22px;
        // padding-right: 47px;
      }
    }

    &-description {
      margin-top: 16px;
      // padding-top: 24px;
      // border-top: 1px solid #b2b4b4;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #3c4242;

      @media screen and (max-width: 1220px) {
        margin-top: 16px;
        // width: 382px;
      }

      @media screen and (max-width: 767px) {
        width: auto;
        border-top: 0;
        margin-top: 16px;
        padding-top: 0;
      }
    }
  }

  &__nav {
    position: relative;
    z-index: 2;
    margin-top: -80px;
    margin-bottom: 40px;

    @media screen and (max-width: 1220px) {
      margin-top: -64px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }

  &__nav-row {
    padding: 0 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 767px) {
      display: none;
    }
    
  }

  &__arrows {
    display: flex;
  }

  &__arrow {
    width: 40px;
    height: 40px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #ebefee;
    color: #830051;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      background-color: #d8dfde;
    }
    &:first-child {
      margin-right: 18px;
    }
  }

  &__arrow-progress {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    & svg {
      width: calc(100% + 14px);
      height: calc(100% + 14px);
      flex-shrink: 0;
    }
    & circle {
      stroke-dasharray: 153;
      stroke-dashoffset: 153;
      transform: rotate(-90deg);
      transform-origin: center;
      stroke: #830051;
      stroke-width: 2px;
      r: 25px;
      cx: 50%;
      cy: 50%;
    }

    &_active {
      & circle {
        animation: loader 5s linear infinite both;
      }
    }
  }

  &__counter {
    margin-right: 25px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #830051;
  }
}

@keyframes loader {
  from {
    stroke-dashoffset: 150;
  }
  to {
    stroke-dashoffset: 0;
  }
}
</style>

<style lang="scss">
.first-slider__slide {
  sup,
  sub {
    vertical-align: super;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: currentColor;
    white-space: nowrap;
  }
  &-btn {
    margin-top: 15px;
    max-width: 203px;
  }
}

.first-slider__slide-description hr {
  @media screen and (max-width: 767px) {
    display: none !important;
  }
}
</style>
